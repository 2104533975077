<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("groupSubjects.title") }} // <span class="group-name">Класс </span> - {{ groupInfo.groupName }}
          </template>
          <template v-slot:toolbar>
            <b-button @click="createFromSchoolProgram" v-if="showEditButton && isCreateFromSchoolProgramButtonAllowed()">{{ $t("groupSubjects.createFromEduProgramButton") }}</b-button>
            <b-button v-b-modal.modal-1 v-if="showForAdmin()">{{ $t("common.new") }}</b-button>
          </template>
          <template v-slot:body>
            <div class="alert alert-danger">
              Дорогие учителя. Не добавляйте предметы по два раза.
              Если есть вопросы сразу обращайтесь к Завучу или ответственному по школе за дневник.
              По ссылке можете найти видеоинструкцию
              <a href="https://www.youtube.com/watch?v=kxCqh1EZHFQ&list=PL7rW8E5_3asAGTuOxNkLFMfCoGbv5-zDZ&index=4"
                 target="_blank" style="font-style: italic; color: black; 
                   text-decoration: underline; font-weight: bold">Инструкция</a>
            </div>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("groupSubjects.list.subject") }}
                  </th>
                  <th class="text-left">
                    {{ $t("groupSubjects.list.name") }}
                  </th>
                  <th class="text-left">
                    {{ $t("groupSubjects.list.roomNumber") }}
                  </th>
                  <th class="text-left">
                    {{ $t("groupSubjects.list.teacher") }}
                  </th>
                  <th class="text-left"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in subjects" :key="item.id" :class="isArchive(item)">
                  <td>{{ item.subjectName }}</td>
                  <td>{{ item.name }}</td>
                  <td>
                    <b-form-select
                        v-model="item.classroomId"
                        :options="classrooms"
                        :state="modelState.classroomId"
                        @change="changeRoom(item)"
                        v-if="item.groupSubjectSubGroupsList.length === 0"
                    ></b-form-select>
                  </td>
                  <td>
                    <div v-for="subgroup in item.groupSubjectSubGroupsList" :key="subgroup.id">
                      {{subgroup.teacherName}}
                    </div>
                    <span v-if="item.groupSubjectSubGroupsList.length == 0">
                    {{ item.teacherName }}
                    </span>
                  </td>
                  <td>
                    <b-button
                        type="submit"
                        @click="editGroupSubject(item)"
                        variant="primary"
                        v-if="showEditButton"
                    >
                      {{ $t("common.edit") }}
                    </b-button>
                    <b-button
                        type="submit"
                        @click="openMarks(item)"
                        variant="primary"
                        v-if="item.groupSubjectSubGroupsList.length == 0"
                    >
                      {{ $t("marks.title") }}
                    </b-button>
                    <b-button
                        v-for="subgroup in item.groupSubjectSubGroupsList" 
                        :key="subgroup.id"
                        type="submit"
                        @click="openSubgroupMarks(subgroup)"
                        variant="primary"
                    >
                      {{ $t("marks.title") }} {{subgroup.subjectName}}
                    </b-button>
                    <b-button
                        type="submit"
                        @click="openSubGroups(item)"
                        variant="primary"
                    >
                      {{ $t("subGroups.title") }}
                    </b-button>    
                    <b-button
                        v-if="showEditButton && item.hasMarks == false"
                        @click="deleteItem(item)"
                        variant="danger"
                    >
                      Удалить
                    </b-button>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div>
              <b-modal id="modal-1" ref="groupSubject-modal">
                <template v-slot:modal-title>
                  {{ $t("common.new") }}
                </template>
                <b-form>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("groupSubjects.list.subject") }}
                    </template>
                    <b-form-select
                        v-model="form.subjectId"
                        :options="subjectDictionary"
                        :state="modelState.subjectId"
                    ></b-form-select>
                    <div class="invalid-feedback">{{ modelSaveErrors.subjectId }}</div>
                  </b-form-group>
                  <b-form-group id="input-group-1" label-for="input-1">
                    <template v-slot:label>
                      {{ $t("groupSubjects.list.name") }}
                    </template>
                    <b-form-input
                        v-model="form.name"
                        :state="modelState.name"
                    ></b-form-input>
                    <div class="invalid-feedback">{{ modelSaveErrors.name }}</div>
                  </b-form-group>

                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("groupSubjects.list.teacher") }}
                    </template>
                    <b-form-select
                        v-model="form.teacherId"
                        :options="teachers"
                        :state="modelState.teacherId"
                    ></b-form-select>
                    <div class="invalid-feedback">{{ modelSaveErrors.teacherId }}</div>
                  </b-form-group>
                
                <b-form-group>
                  <template v-slot:label>
                    {{ $t("groupSubjects.list.classWorkPerWeekHours") }}
                  </template>
                  <b-form-select
                      v-model="form.classWorkPerWeekHours"
                      :options="classWorkPerWeekHoursList"
                      :state="modelState.classWorkPerWeekHours"
                  ></b-form-select>
                  <div class="invalid-feedback">{{ modelSaveErrors.classWorkPerWeekHours }}</div>
                </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("groupSubjects.list.roomNumber") }}
                    </template>
<!--                    <b-form-input
                        v-model="form.roomNumber"
                        :state="modelState.roomNumber"
                    ></b-form-input>-->
                    <b-form-select
                        v-model="form.classroomId"
                        :options="classrooms"
                        :state="modelState.classroomId"
                    ></b-form-select>
                    <div class="invalid-feedback">{{ modelSaveErrors.classroomId }}</div>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox v-model="form.isAdditional">
                      Дополнительный урок (школьный или иной компонент)
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox v-model="form.allowNoQuarterMarks">
                      Четвертные оценки не ставятся
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group v-if="showForAdmin()">
                    <b-form-checkbox v-model="form.allowNoRatings">
                      Разрешить не ставить оценки
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group v-if="showForAdmin()">
                    <b-form-checkbox
                        v-model="form.allowNoWriteLessonContent"
                    >Разрешить не писать тему уроков
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group v-if="showForAdmin()">
                    <b-form-checkbox
                        v-model="form.allowNoWriteHomeWork"
                    >
                      Разрешить не писать домашнее задание
                    </b-form-checkbox>
                  </b-form-group>    
                  <b-form-group v-if="showForAdmin()">
                    <b-form-checkbox
                        v-model="form.allowTopicsFilledInAdvance"
                    >
                      Разрешить наперед писать темы
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group v-if="showForAdmin()">
                    <template v-slot:label>
                      Миниммальное количество оценок за день
                    </template>
                    <b-form-input
                        v-model="form.minMarksAmountToUnlockNextLesson"
                    >
                    </b-form-input>
                  </b-form-group>        
                  <b-form-group>
                    <b-form-checkbox v-model="form.archive">
                      Архив
                    </b-form-checkbox>
                  </b-form-group>
                </b-form>

                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onSubmit" variant="primary" id="save-subject-data-button">
                    {{ $t("common.save") }}
                  </b-button>
                  <b-button type="reset" @click="onReset" variant="danger">
                    {{ $t("common.cancel") }}
                  </b-button>
                </template>
              </b-modal>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";
import {mapGetters} from "vuex";

export default {
  name: "groupSubjects",
  components: {
    KTPortlet
  },
  data() {
    return {
      groupId: null,
      subjects: [],
      subjectDictionary: null,
      form: {
        name: "",
        subjectId: "",
        teacherId: "",
        groupId: "",
        classWorkPerWeekHours: "",
        roomNumber: "",
        isAdditional: false,
        allowNoRatings: false,
        allowNoWriteLessonContent: false,
        allowNoWriteHomeWork: false,
        allowNoQuarterMarks: false,
        archive: false,
        classroomId: 0,
      },
      teachers: [],
      groupInfo: {},
      classWorkPerWeekHoursList: [],
      classrooms: [],
      modelState: {},
      modelSaveErrors: {},
      showEditButton: false
    };
  },
  mounted() {
   
    this.classWorkPerWeekHoursList = DictionariesService.getClassWorkPerWeekHoursList();
    
    this.reloadData();
    let $this = this;
    ApiService.querySecured("dictionaries/teachersByGroup", {
      params: {groupId: this.$route.params.id}
    }).then(({data}) => {
      $this.teachers = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });

    ApiService.querySecured("dictionaries/subjects", {
      params: {groupId: this.$route.params.id}
    }).then(({data}) => {
      $this.subjectDictionary = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });

    ApiService.querySecured("studentGroups/find", {
      params: {id: this.$route.params.id}
    }).then(({data}) => {
      $this.groupInfo = data;
    }).catch(({response}) => {
      console.log(response);
    });

    ApiService.querySecured("access/getUserAccessToSubjects", {
      params: {groupId: this.$route.params.id}
    }).then(({data}) => {
      $this.showEditButton = data.accessEditing;
    }).catch(({response}) => {
      console.log(response);
    });

    ApiService.querySecured("dictionaries/getClassrooms", {}).then(({data}) => {
      $this.classrooms = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ])
  },
  methods: {
    onSubmit(evt) {
      let submitButton = document.getElementById("save-subject-data-button");
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");
      
      evt.preventDefault();
      let $this = this;
      this.form.groupId = this.$route.params.id;

      ApiService.postSecured("groupSubjects/save", this.form)
          .then(function () {
            $this.$refs["groupSubject-modal"].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.modelSaveErrors = response.data.errors;

              for (let i in response.data.errors) {

                $this.modelState[i] = false;
              }
            }
          }).finally(function () {
            submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
          });
    },
    changeRoom(item) {
      let $this = this;
      
      ApiService.postSecured("groupSubjects/save", item)
          .then(function () {
            $this.reloadData();
          })
          .catch(({response}) => {
            console.log(response);
          }).finally(function () {
      });
    },
    deleteItem(groupSubject) {
      let $this = this;
      if(confirm("Удалить предмет?")){
        ApiService.postSecured("groupSubjects/delete", {id:groupSubject.id})
            .then(function () {
              
              $this.reloadData();
            })
            .catch(() => {
              
            }).finally(function () {
          
        });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["groupSubject-modal"].hide();
    },
    reloadData() {
      ApiService.querySecured("groupSubjects/list", {
        params: {groupId: this.$route.params.id}
      }).then(({data}) => {
        this.subjects = data.list;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    cleanModel() {
      this.form = {};
    },
    createFromSchoolProgram() {
      let $this = this;
      ApiService.postSecured("groupSubjects/createFromSchoolProgram", {groupId: this.$route.params.id})
          .then(() => {
        $this.reloadData();
        
      }).catch(({response}) => {
        console.log(response);
      });
    }, 
    editGroupSubject(groupSubject) {
      this.form = groupSubject;
      this.$refs["groupSubject-modal"].show();
    },
    openMarks(groupSubject) {
      this.$router.push({name: "marks", params: {id: groupSubject.id}});
    },
    openSubgroupMarks(subgroup) {
      this.$router.push({name: "marks", params: {id: "sub-" + subgroup.id}});
    },
    openSubGroups(groupSubject) {
      this.$router.push({name: "groupSubjectsSubGroup", params: {id: groupSubject.id, groupId:groupSubject.groupId}});
    },
    showForAdmin(){
      return this.currentUser.role == 'ROLE_SCHOOL_ADMIN';
    },
    isCreateFromSchoolProgramButtonAllowed(){
      return this.subjects.length === 0;
    },
    isArchive(subject) {
      return subject.archive === true ? "table-danger" : "";
    },
  }
};
</script>
